<template>
  <default-view class="mt-n6">
    <v-dialog
      ref="crudDialog"
      v-model="addEventDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mt-n12" color="primary" dark v-bind="attrs" v-on="on">
          AJOUTER UNE DEMANDE
        </v-btn>
      </template>
      <add-support-form
        :overlay="overlay"
        @canceled="closeEventDialog"
        @addSupport="addSupport"
      ></add-support-form>
    </v-dialog>
    <v-row
      align="start"
      no-gutters
      justify="center"
      class="fill-height mt-n12 mx-n3"
    >
      <v-col cols="12">
        <onglet-technique> </onglet-technique>
      </v-col>
    </v-row>
  </default-view>
</template>
<script>
import OngletTechnique from "../components/support/Onglet";
import DefaultView from "../components/DefaultView";
import AddSupportForm from "../components/support/AddSupport.vue";
import TICKET_API from "../api/ticket";
import Swal from "sweetalert2";

export default {
  components: { OngletTechnique, DefaultView, AddSupportForm },
  data: () => ({
    overlay: false,
    addEventDialog: false
  }),
  methods: {
    addSupport({ input }) {
      let urlcourante = document.location.href;
      //let ent = urlcourante.split("/")[5];
      let it = urlcourante.split("/")[9];
      let itID = urlcourante.split("/")[11];
      let cat = input.category == "BUG" ? 9 : 10;
      const payload = {
        name: JSON.stringify({
          itemtype: it,
          itemtypeID: itID,
          titre: input.title,
          mail: input.email,
          tel: input.tel
        }),
        requesttypes_id: 7,
        type: 1,
        locations_id: itID,
        itilcategories_id: cat,
        content: input.content
        //entities_id: ent,
        //is_visible: 1,
      };

      this.overlay = true;

      TICKET_API.createTicket(payload)
        .then(() => {
          this.addEventDialog = false;
          this.overlay = false;
          if (this.isDirectLink) {
            Swal.fire({
              title: "Merci de votre demande!",
              text: "Vous allez recevoir un mail de suivi",
              icon: "success",
              showConfirmButton: false
            });
          } else {
            Swal.fire(
              "Merci de votre demande!",
              "Vous allez recevoir un mail de confirmation",
              "success"
            );
          }
        })
        .finally(() => {
          if (this.isDirectLink) {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Error de creation"
          });
        });
    },
    closeEventDialog() {
      this.addEventDialog = false;
    }
  },
  computed: {
    isDirectLink() {
      return this.$route.meta.directLink;
    }
  }
};
</script>
