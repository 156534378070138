<template>
  <ticket filtered>
    <template v-slot="slotProps">
      <tab-onglet
        v-if="Object.keys(slotProps.exposedData.tickets_items).length > 1"
        :exposed-data="slotProps.exposedData"
      >
      </tab-onglet>
      <v-row align="start" justify="center" class="fill-height mx-0" v-else>
        <v-col cols="12" class="px-0 px-md-0">
          <incident
            :loaded="slotProps.exposedData.loaded"
            :loading="slotProps.exposedData.loading"
            :tickets="getFirstElement(slotProps.exposedData.tickets_items)"
          ></incident>
        </v-col>
      </v-row>
    </template>
  </ticket>
</template>
<script>
import Incident from "./Incident";
import Ticket from "./Ticket";
import TabOnglet from "./TabOnglet";
import { mapGetters } from "vuex";

export default {
  name: "Onglet",
  components: { Incident, Ticket, TabOnglet },
  computed: {
    ...mapGetters(["getActiveEntity"])
  },
  methods: {
    getFirstElement(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      if (keys.length > 0) {
        return ticketsItems[keys[0]];
      }
      return [];
    },
    getSearchKey(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      if (keys.length > 0) {
        return keys[0].toLowerCase().startsWith("plugingenericobject")
          ? "equipment"
          : "name";
      }
      return "name";
    },
    getTypeName(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      const type = keys.length > 0 ? keys[0] : "location";
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : states;
      let typeName = states && states[type] ? states[type] : null;
      if (type === "location" && !typeName) {
        return "Emplacement";
      }
      return typeName ? typeName : type;
    }
  }
};
</script>
